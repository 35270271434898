<template>
  <div id="app">
    <h1>Centrifugal Test</h1>
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <CentrifugalTest />
    <!-- <ShowImg/> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import ShowImg from './components/ShowImg.vue'
import CentrifugalTest from './components/CentrifugalTest.vue'
export default {
  name: 'App',
  components: {
    // HelloWorld,
    // ShowImg
    CentrifugalTest
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
