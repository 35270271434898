<template>
  <div>
    <div>
      <p class="label-txt">URL Socket</p>
      <input
        class="text-field"
        type="text"
        name="url"
        id=""
        placeholder="wss://test.local.in/connection/websocket"
        v-model="url"
      />
    </div>
    <div>
      <p class="label-txt">JWT Token</p>
      <input
        class="text-field"
        type="text"
        name="jwt"
        id=""
        placeholder="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIi...."
        v-model="jwt"
      />
    </div>
    <div>
      <p class="label-txt">Subscribe Event Name</p>
      <input
        class="text-field"
        type="text"
        name="subscribe"
        id=""
        placeholder="message-event"
        v-model="eventName"
      />
    </div>
    <div>
      <button class="save-button" @click="save()">Save</button>
      <button class="save-button" @click="clear()">Clear</button>
    </div>
    <div class="result-container">
      <pre>{{ listMessage }}</pre>
    </div>
  </div>
</template>

<script>
// var Centrifuge = require("centrifuge");
export default {
  name: "CentrifugalTest",
  data() {
    return {
      url: "",
      jwt: "",
      eventName: "",
      listMessage: [],
    };
  },
  mounted() {
    this.url = localStorage.getItem("centrifugal-url") ?? "";
    this.jwt = localStorage.getItem("centrifugal-jwt") ?? "";
    this.eventName = localStorage.getItem("centrifugal-eventName") ?? "";
    var Centrifuge = require("centrifuge");
    var centrifuge = new Centrifuge(this.url);

    centrifuge.setToken(this.jwt);

    var that = this;
    centrifuge.subscribe(this.eventName, function (message) {
      console.log(message);
      that.listMessage.push(message);
    });

    centrifuge.connect();
  },
  methods: {
    save() {
      localStorage.setItem("centrifugal-url", this.url);
      localStorage.setItem("centrifugal-jwt", this.jwt);
      localStorage.setItem("centrifugal-eventName", this.eventName);
      this.clear();
      location.reload();
    },
    clear() {
      this.listMessage = [];
    },
  },
};
</script>


<style scoped>
.text-field {
  font-size: 20px;
  width: 80%;
}
.label-txt {
  font-size: 20px;
  font-weight: bold;
}
.result-container {
  text-align: left;
  padding: 1rem;
  font-size: 16px;
}
.save-button {
  margin: 1rem;
  padding: 10px 15px;
  font-size: 18px;
}
</style>