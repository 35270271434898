import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios)
Vue.use(require('vue-cookies'))
Vue.config.productionTip = false

// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

new Vue({
  render: h => h(App),
}).$mount('#app')
